<template>
  <div>
    <Landing
      :role="role"
      :tabs="tabs"
      :subTitle="subTitle"
      :mainTabs="mainTabs"
      :stories="stories"
      :storiesTitle="storiesTitle"
    ></Landing>
    <div class="container mb-4">
      <Plan></Plan>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Landing from "@/views/landingPage/components/Index.vue";
import Plan from "@/views/plans/Students.vue";

export default {
  metaInfo() {
    return {
      title: `For ${this.role} - ` + this.CompanyName
    };
  },

  components: { Landing, Plan },

  mixins: [],

  props: [],
  data() {
    return {
      //Role
      role: "Students",
      //SubTitle
      subTitle: {
        title: "The everything site for test prep",
        smallTitle: "From your first diagnostic to your final mock test"
      },
      // Tabs
      tabs: [
        {
          icon: "fas fa-pencil-ruler",
          title: "Answer Explanations",
          img:
            "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage-Students/Answer+Explanations.png"
        },
        {
          icon: "fas fa-stopwatch",
          title: "Time Tracking",
          img:
            "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage-Students/Time+Tracking.png"
        },
        {
          icon: "fas fa-grin-stars",
          title: "AI-Powered Study Plan",
          img:
            "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage-Students/AI-Powered+Study+Plan.png"
        },
        {
          icon: "fas fa-house-user",
          title: "Adaptive Practice Tests",
          img:
            "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage-Students/Adaptive+Practice+Tests.png"
        },
        {
          icon: "fas fa-chart-bar",
          title: "Performance Analysis",
          img:
            "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage-Students/Performance+Analysis.png"
        },
        {
          icon: "fas fa-mail-bulk",
          title: "Drills",
          img:
            "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage-Students/Drills.png"
        },
        {
          icon: "fas fa-spell-check",
          title: "Individual Modules",
          img:
            "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage-Students/Individual+Modules.png"
        },
        {
          icon: "fas fa-book",
          title: "Flash Cards",
          img:
            "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage-Students/Flash+Cards.png"
        }
      ],
      // mainTabs
      mainTabs: [
        {
          title: "Make a plan",
          text:
            "Get started by creating a game plan for your test prep by using our AI-powered study plan generator",
          img: ""
        },
        {
          title: "Drill & Review",
          text: "Master every concept with detailed answer explanations and targeted practice designed to boost your score",
          img: ""
        },
        {
          title: "Crush goals",
          text: "Track your progress and achieve your goals with data-driven insights and real-time adjustments",
          img: ""
        }
      ],
      // Stories
      stories: [
        {
          id: 1,
          video: "",
          cover:
            "https://images.ctfassets.net/w8fc6tgspyjz/6cGz5aYNJjTzReFVdNayz7/83b3524d84117f3fb44c7eaa63ca7eb6/CONVENE.png?fm=avif&q=50&w=800",
          words: "ClickUp is serving us so we can serve our pet guardians.",
          name: "Samantha Dengate"
        },
        {
          id: 1,
          video: "",
          cover:
            "https://images.ctfassets.net/w8fc6tgspyjz/6cGz5aYNJjTzReFVdNayz7/83b3524d84117f3fb44c7eaa63ca7eb6/CONVENE.png?fm=avif&q=50&w=800",
          words: "ClickUp is serving us so we can serve our pet guardians.",
          name: "Samantha Dengate"
        },
        {
          id: 1,
          video: "",
          cover:
            "https://images.ctfassets.net/w8fc6tgspyjz/6cGz5aYNJjTzReFVdNayz7/83b3524d84117f3fb44c7eaa63ca7eb6/CONVENE.png?fm=avif&q=50&w=800",
          words: "ClickUp is serving us so we can serve our pet guardians.",
          name: "Samantha Dengate"
        },
        {
          id: 1,
          video: "",
          cover:
            "https://images.ctfassets.net/w8fc6tgspyjz/6cGz5aYNJjTzReFVdNayz7/83b3524d84117f3fb44c7eaa63ca7eb6/CONVENE.png?fm=avif&q=50&w=800",
          words: "ClickUp is serving us so we can serve our pet guardians.",
          name: "Samantha Dengate"
        },
        {
          id: 1,
          video: "",
          cover:
            "https://images.ctfassets.net/w8fc6tgspyjz/6cGz5aYNJjTzReFVdNayz7/83b3524d84117f3fb44c7eaa63ca7eb6/CONVENE.png?fm=avif&q=50&w=800",
          words: "ClickUp is serving us so we can serve our pet guardians.",
          name: "Samantha Dengate"
        },
        {
          id: 1,
          video: "",
          cover:
            "https://images.ctfassets.net/w8fc6tgspyjz/6cGz5aYNJjTzReFVdNayz7/83b3524d84117f3fb44c7eaa63ca7eb6/CONVENE.png?fm=avif&q=50&w=800",
          words: "",
          name: ""
        },
        {
          id: 1,
          video: "",
          cover:
            "https://images.ctfassets.net/w8fc6tgspyjz/6cGz5aYNJjTzReFVdNayz7/83b3524d84117f3fb44c7eaa63ca7eb6/CONVENE.png?fm=avif&q=50&w=800",
          words: "",
          name: ""
        },
        {
          id: 1,
          video: "",
          cover:
            "https://images.ctfassets.net/w8fc6tgspyjz/6cGz5aYNJjTzReFVdNayz7/83b3524d84117f3fb44c7eaa63ca7eb6/CONVENE.png?fm=avif&q=50&w=800",
          words: "",
          name: ""
        },
        {
          id: 1,
          video: "",
          cover:
            "https://images.ctfassets.net/w8fc6tgspyjz/6cGz5aYNJjTzReFVdNayz7/83b3524d84117f3fb44c7eaa63ca7eb6/CONVENE.png?fm=avif&q=50&w=800",
          words: "",
          name: ""
        }
      ],
      storiesTitle: {
        title: "Student Success Stories",
        smallTitle:
          "Perfect scores, dramatic improvement, and AP test domination<br />These students used XMocks to crush their goals!",
        count: "4,000"
      }
    };
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped></style>
