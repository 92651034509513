import { render, staticRenderFns } from "./Students.vue?vue&type=template&id=0693cf5b&scoped=true"
import script from "./Students.vue?vue&type=script&lang=js"
export * from "./Students.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0693cf5b",
  null
  
)

export default component.exports